<template>
  <ScaleScreen
    :width="1920"
    :height="1080"
    class="scale-wrap"
    :selfAdaption="true"
    fullScreen
  >
    <div id="index" ref="appRef">
      <div class="bg">
        <dv-loading v-if="loading">Loading...</dv-loading>
        <div v-else class="host-body">
          <div class="d-flex headerWrapper">
            <!-- <div class="d-flex aside-width">
              <div
                class="react-left react-l-s leftBox"
                v-for="(item, index) in leftTabData"
                :key="index"
                :class="{ active: item.value === currentTab }"
                @click="routeChange(item.value)"
              >
                <span class="react-left"></span>
                <span class="text fw-b">{{ item.label }}</span>
              </div>
            </div> -->
            <!-- <div class="d-flex aside-width">
              <div
                class="react-right rightBox"
                v-for="(item, index) in rightTabData"
                :key="index"
                :class="{ active: item.value === currentTab }"
                @click="routeChange(item.value)"
              >
                <span class="text fw-b">{{ item.label }}</span>
              </div>

              <div class="react-right react-l-s">
                <span class="react-after"></span>
                <span class="text">{{ dateYear }} {{ dateWeek }}</span>
              </div>
            </div> -->
            <div class="react-right react-l-s d-flex rightWrapper">
                <span class="iconfont icon-shizhong" style="margin-right: 10px;font-size: 16px;" />
                <span class="text">{{ dateYear }}&nbsp; {{ dateDay }}</span>
                <span class="iconfont icon-wendu" style="margin-left: 21px;margin-right: 10px;font-size: 16px;" />
                <span>22-28 °c</span>
              </div>
          </div>
          <layoutMain></layoutMain>
        </div>
      </div>
    </div>
  </ScaleScreen>
</template>

<script>
// import drawMixin from "@/utils/drawMixin";
import { formatTime } from '@/utils/index.js'
import layoutMain from '@/components/layout/index.vue'
import ScaleScreen from '@/components/scale-screen/scale-screen.vue'

export default {
  // mixins: [drawMixin],
  data() {
    return {
      timing: null,
      loading: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
      decorationColor: ['#568aea', '#000000'],
      leftTabData: [
        {
          label: '健身锻炼综合数据',
          value: '/integratedData',
        },
        {
          label: '赛事活动',
          value: '/matchActivity',
        },
        {
          label: '体育场地设施',
          value: '/sportFacilities',
        },
        {
          label: '体育社会组织',
          value: 'sportsSociety',
        },
        {
          label: '社会指导员',
          value: '/socialInstructor',
        },
      ],
      rightTabData: [
        {
          label: '百姓健身房',
          value: '/fitnessSite',
        },
        {
          label: '基层体育委员组织',
          value: '/grassrootsSports',
        },
        {
          label: '百姓运动码',
          value: '/sportCode',
        },
        {
          label: '运动银行',
          value: '/sportsBank',
        },
      ],
      currentTab: '/integratedData',
    }
  },
  components: {
    // centerLeft1,
    // centerLeft2,
    // centerRight1,
    // centerRight2,
    // center,
    // bottomLeft,
    // bottomRight
    layoutMain,
    ScaleScreen,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.getCurrentIndex(to.path)
    })
  },
  mounted() {
    this.timeFn()
    this.cancelLoading()
  },
  beforeDestroy() {
    clearInterval(this.timing)
  },
  methods: {
    timeFn() {
      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), 'HH: mm: ss')
        this.dateYear = formatTime(new Date(), 'yyyy-MM-dd')
        this.dateWeek = this.weekday[new Date().getDay()]
      }, 1000)
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    routeChange(url) {
      this.currentTab = url
      this.$router.push(`${url}`)
    },
    getCurrentIndex(path) {
      this.currentTab = path
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/index.scss';

.scale-wrap {
  .bg {
    width: 100%;
    height: 100%;
    // padding: 16px 16px 0 16px;
    background-image: url('../assets/pageBg.png');
    background-size: cover;
    background-position: center center;

    .headerWrapper {
      height: 84px;
      width: 100%;
      background-image: url('../assets/header.png');
      background-size: cover;
      // background-position: center center;
      z-index: 10;
      justify-content: flex-end;
      & > div {
        padding-top: 38px;
      }
      .rightWrapper {
        align-items: center;
        margin-top: 16px;
      }
    }
  }
}
</style>
